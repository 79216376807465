import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Box,
    Heading,
    FormControl,
    FormLabel,
    HStack,
    Divider,
    Input,
    Select,
    Button,
    useToast
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { Register } from '../service/service.member'
import { useFetchChurch } from '../customHooks/useFetchChurchs'

export const Create = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const id = user['id']
    const { churchs } = useFetchChurch(`https://miembrosback.gdzul.com/api/getchurch/${id}`)
    const toast = useToast()
    // Se inizializa el formulario
    const form = useFormik({
        initialValues: {
            Nombre_miembro: '',
            Telefono_miembro: '',
            Sexo_miembro: '',
            Id_church: '',
            Id_pastor:id
        }, onSubmit: (values, {resetForm}) => {
            Register( {values} ).then((res) => {
                if (res == '200') {
                    toast({
                        title: 'Exitoso',
                        description: "Miembro Registrado Correctamente",
                        status: 'success',
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                      })
                } else {
                    toast({
                        title: 'Error',
                        description: "Error al registrar el miembro",
                        status: 'error',
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                      })
                }
            })
            resetForm()
        },
    })
    return (
        <>
            <Card w='90%'>
                <CardHeader> <Heading size='md'>Registro de Miembros Viudos/Viudas</Heading> </CardHeader>
                <Divider height='10px' w='97.5%' ml='1%' color='#EEF1F7' />
                <CardBody>
                    <form onSubmit={form.handleSubmit}>
                        <HStack>
                            <FormControl>
                                <Box w='90%'>
                                    <FormLabel>Iglesia</FormLabel>
                                    <Select placeholder='Selecciona una opción'
                                        id='Id_church'
                                        name='Id_church'
                                        type='text'
                                        onChange={form.handleChange}
                                        value={form.values.Id_church}
                                    >
                                        {churchs?.map((church) =>
                                            (
                                                <option key={church.Id_church} value={church.Id_church}>{church.Nombre_iglesia}</option>
                                            )
                                            )}


                                    </Select>

                                </Box>


                                <Box w='90%'>
                                    <FormLabel>Nombre Miembro</FormLabel>
                                    <Input
                                        id='Nombre_miembro'
                                        name='Nombre_miembro'
                                        type='text'
                                        onChange={form.handleChange}
                                        value={form.values.Nombre_miembro}
                                    />
                                </Box>

                                <Box w='90%'>
                                    <FormLabel>Telefono</FormLabel>
                                    <Input
                                        id='Telefono_miembro'
                                        name='Telefono_miembro'
                                        type='number'
                                        onChange={form.handleChange}
                                        value={form.values.Telefono_miembro}
                                    />
                                </Box>


                                <Box w='90%'>
                                    <FormLabel>Sexo</FormLabel>
                                    <Select placeholder='Selecciona una opción' id='Sexo_miembro' name='Sexo_miembro' onChange={form.handleChange}
                                        value={form.values.Sexo_miembro}
                                    >
                                        <option value='M'>Masculino</option>
                                        <option value='F'>Femenino</option>
                                    </Select>
                                </Box>
                            </FormControl>

                        </HStack>
                        <Button mt={5} w='15%' colorScheme='facebook' type='submit'>Registrar</Button>
                    </form>

                </CardBody>
            </Card>
        </>
    )
}
