import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Image
} from '@chakra-ui/react';
import { useState } from 'react';

import { Loggin } from '../../service/service.loggin';

export const Login = () => {

    const Logo = process.env.PUBLIC_URL + '/logoiasd.png'
    const banner = process.env.PUBLIC_URL + '/versiculo.jpg'
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const HandelSubmit = () => {
        Loggin({ email, password })

    }
    return (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>

            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={4} w={'full'} maxW={'md'}>
                    <Image width='60%' marginLeft='20%' marginTop='-20%' marginBottom='5%' src={Logo} alt="Logo" />
                    <Heading fontSize={'5xl'} textAlign={'center'}>Ministerio de acompañamiento</Heading>
                    <FormControl marginLeft='-10%' id="email">
                        <FormLabel>Correo Electronico</FormLabel>
                        <Input type="email" onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>
                    <FormControl id="password">
                        <FormLabel>Contraseña</FormLabel>
                        <Input type="password" onChange={(e) => setPassword(e.target.value)} />
                    </FormControl>
                    <Stack spacing={6}>
                        <Button colorScheme={'blue'} onClick={HandelSubmit} variant={'solid'}>
                            Iniciar Sessión
                        </Button>
                    </Stack>
                </Stack>
            </Flex>
            <Flex flex={1}>
                <Image
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={banner}
                />
            </Flex>
        </Stack>
    );
}