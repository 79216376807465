import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppLayout } from '../components/layout/AppLayout'
import { Home } from '../pages/Home'
import { Create } from '../pages/Create'
import { Members } from '../pages/Members'

import { AuthRoute } from './AuthRoute'

// Authenticated
import { Login } from '../components/Login/Login'
//import { Register } from '../components/Register/Register'

export const Rout = () => {
  return (
    <Routes>
      <Route element={<AuthRoute />} >
        <Route element={<AppLayout />} >
          <Route path='/home' element={<Home />} />
          <Route path='/create' element={<Create />} />
          <Route path='/members' element={<Members />} />
          {/* 
            <Route path='/user' element={<Home />} />
            <Route path='/order' element={<Home />} /> 
          */}
        </Route>
      </Route>
       <Route index path='/' element={<Login />} />
      {/*<Route path='/register' element={<Register />} /> */}
    </Routes>
  )
}
