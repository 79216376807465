import axios from "axios";
import { useState, useEffect } from "react";

export function useFetchChurch(url) { 
    //console.log(url)
    const [churchs, setChurchs] = useState([]);

    useEffect(() => {
           axios.get(url).then(function (res) {
            setChurchs(res.data);
           })
    }, [url]);

    return { churchs };
}