import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button
} from '@chakra-ui/react'
import React, {useState, useEffect} from 'react'
import * as XLSX from 'xlsx'
import axios from 'axios';

export function useFetchMembers(url) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    axios.get(url).then(function (res) {
      setMembers(res.data);
    });
  }, [url]);

  return { members };
}

export function useMembers(rol, id) {
  const membersAdmin = useFetchMembers(`https://miembrosback.gdzul.com/api/getmembers`);
  const members  = useFetchMembers(`https://miembrosback.gdzul.com/api/getmembers/${id}`);

  const membersList = rol === 2 ? members.members || [] : membersAdmin.members || [];

  const handleExport = () => {
    const data = membersList.map((member) => ({
      Nombre_miembro: member.Nombre_miembro,
      Telefono_miembro: member.Telefono_miembro,
      Nombre_iglesia: member.church.Nombre_iglesia,
      Sexo_miembro: member.Sexo_miembro,
    }));

    // Crear hoja de cálculo con SheetJS
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Miembros');
    XLSX.writeFile(wb, 'Miembros.xlsx');
  }

  return { membersList, handleExport };
  
}

export const Members = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user['id']
  const rol = user['role']
  
  const { membersList, handleExport } = useMembers(rol, id);
  console.log(membersList)
  return (
    <TableContainer>
      <Table variant='simple' style={{ marginLeft: '-1%' }}>
        <TableCaption> <Button colorScheme='whatsapp' onClick={handleExport}>Exportar</Button></TableCaption>
        <Thead>
          <Tr>
            <Th>Nombre</Th>
            <Th>Telefono</Th>
            <Th>Nombre de la Iglesia</Th>
            <Th>Sexo</Th>
          </Tr>
        </Thead>

        <Tbody>

          {membersList?.map((member) => (
              <Tr key={member.Nombre_miembro}>
                <Td>{member.Nombre_miembro}</Td>
                <Td>{member.Telefono_miembro}</Td>
                <Td>{member.church.Nombre_iglesia}</Td>
                <Td>{member.Sexo_miembro}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  )
}
