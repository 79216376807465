import axios from "axios";

export async function Register({values}){
    let stat
    await axios.post(`https://miembrosback.gdzul.com/api/createm`, {values}).then(function (res) {
        if(res.status == '200'){
            stat = res.status 
        }else{
            stat = res.status
        }
    }).catch(function (error) {
        console.log(error);
    });
    return stat
}

