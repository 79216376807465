import axios from "axios";

export async function Loggin({email, password}){
    await axios.post(`https://miembrosback.gdzul.com/api/login`, {email, password}).then(function (res) {
        localStorage.setItem('auth', 'true')
        localStorage.setItem('user', JSON.stringify(res.data));
         const user = localStorage.getItem('user');
        if (user != null) {
            window.location.href = "/home"
        }
    }).catch(function (error) {
        console.log(error)
    })
   
    
}

