import {
    Box,
    Heading,
    Container,
    Text,
    Stack,
    Alert,
    AlertDescription
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';


export const Home = () => {
    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>

            <Container maxW={'7xl'} style={{ marginLeft: '5%' }}>
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 20, md: 36 }}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        Bienvenido <br />
                        <Text as={'span'} color={'blue.700'}>
                            Pastor
                        </Text>
                    </Heading>
                    <Alert

                        style={{ borderRadius: '10px' }}
                        textAlign={'left'}
                        fontSize={'xl'}
                        height={'auto'}
                        fontWeight={'semibold'}
                    >
                        <AlertDescription style={{ lineHeight: '35px' }}>

                            La Asociación del Noreste, a través de esta aplicación, pretende recabar una base de datos de todas las personas viudas que son miembros de iglesia. El objetivo es identificarlas para atender sus necesidades en la medida de las posibilidades tanto de la iglesia como de sus miembros.

                            <br />Escriba el nombre, teléfono, iglesia y sexo de cada persona en esta condición que son miembros de sus iglesias.

                           <br /> Muchas gracias por su participación en este proyecto.
                            <br /> <br />
                            <div style={{ textAlign: 'center' }}>
                                <em>
                                    “Entre todos aquellos cuyas necesidades demandan nuestro interés, <strong>las viudas</strong> y <strong>los huérfanos</strong> tienen el mayor derecho a nuestra <strong>tierna simpatía y cuidado</strong>”
                                    <em> - Ministerio de la bondad Pág. 3 -</em>
                                </em>
                            </div>

                        </AlertDescription>
                    </Alert>
                    <Stack
                        direction={'column'}
                        spacing={3}
                        align={'center'}
                        alignSelf={'center'}
                        position={'relative'}>
                    </Stack>
                </Stack>
            </Container>
        </>
    );
};
